import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Avatar,
} from "@mui/material";

import { Outlet } from "react-router-dom";
import StringToColor from "../Common/StringToColor";
import { useContext } from "react";
import { AppContext } from "../Context/AppContext";
import { CSSContext } from "../Context/CSSContext";

const Layout = (props) => {
  // const { themeMode, triggerTheme } = useContext(ThemeContext);
  const { partyName } = useContext(AppContext);
  const { titleBarBackgroundColor, titleBarForegroundColor } = useContext(CSSContext)
  return (
    <>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <AppBar position="fixed" sx={{backgroundColor :titleBarBackgroundColor, color:titleBarForegroundColor, height:'64px' }}>
          <Toolbar sx={{ height:'64px'}}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, marginLeft: "28px" }}
            >
              {partyName}
            </Typography>

            {/* <IconButton
              color="inherit"
              onClick={() => {
                triggerTheme();
              }}
              sx={{ marginLeft: "5px" }}
            >
              {themeMode === "light" ? (
                <DarkModeOutlined />
              ) : (
                <LightModeOutlined />
              )}
            </IconButton> */}
            
            {/* <IconButton
              color="inherit"
              size="small"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            > */}
              <Box>
              <Avatar {...StringToColor()}></Avatar>
              </Box>
            {/* </IconButton> */}
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={()=>{Navigate("/reset_password")}}>Reset password</MenuItem>
              <MenuItem onClick={logOut}>Logout</MenuItem>
            </Menu> */}
          </Toolbar>
        </AppBar>
      </Box>

      <Toolbar id="back-to-top-anchor" sx={{ height:'64px'}}/>
      <Box sx={{ overflowY: "hidden" }} component="main">
        <Outlet />
      </Box>
      {/* <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop> */}
    </>
  );
};

export default Layout;
