import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./Route/privateRoute.jsx";
import "./App.css";
import { AppContextProvider } from "./Components/Context/AppContext";
import { AuthContextProvider } from "./Components/Context/AuthContext";
import { ServiceContextProvider } from "./Components/Context/ServiceContext";
// import { ThemesetProvider } from "./Components/Templates/Theme";
import { CSSContextProvider } from "./Components/Context/CSSContext.jsx"
import { SnackbarProvider } from "notistack";
import Layout from "./Components/Templates/Layout";
import OmniLayout from "./Components/OmniChannel/OmniLayout";
import Login from "./Components/Common/Login";
import Logout from "./Components/Common/Logout";
import ResetPassword from "./Components/Common/ResetPassword";
import Signup from "./Components/Common/Signup";
import NotFound from "./Components/Common/NotFound";
import TenantInvalid from "./Components/Common/TenantInvalid";
// import Dashboard from "./Components/Common/Dashboard";
// import Redirect from "./Components/Common/Redirect"
import { SocketContextProvider } from "./Components/Context/SocketContext";
function App() {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <AuthContextProvider>
          {/* <ThemesetProvider> */}
            <SnackbarProvider maxSnack={5}>
              <ServiceContextProvider>
                <CSSContextProvider>
                  <Routes>
                      {/* <Route path="" exact={true} element={<Redirect />} /> */}
                      <Route path="/login" exact={true} element={<Login />} />
                      <Route path="/signup" exact={true} element={<Signup />} />
                      <Route path="/logout" element={<Logout />} />
                      <Route path="/*" element={<NotFound />} />
                      <Route path="/partyinvalid" element={<TenantInvalid />} />
                      <Route element={<PrivateRoute />}>
                        <Route  element={<Layout />}>
                          <Route
                            path="/"
                            element={<SocketContextProvider />}
                          >
                            <Route path="/" exact element={<OmniLayout />} />
                          </Route>
                          <Route path="/reset_password" exact element={<ResetPassword />} />
                        </Route>
                      </Route>
                    
                  </Routes>
                </CSSContextProvider>
              </ServiceContextProvider>
            </SnackbarProvider>
          {/* </ThemesetProvider> */}
        </AuthContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  );
}
export default App;
