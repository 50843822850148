import axios from "axios";
import AppSettings from "../../Settings/AppSettings";
import React, { createContext, useContext } from "react";
import {  useNavigate } from "react-router-dom";
import { AppContext } from "./AppContext";
const local__storage_key =
  "CordTravel.consolidated.concierge.chat.Logged.user.Object";
const local__storage_token_key =
  "CordTravel.consolidated.concierge.chat.token.Object";

export const AuthContext = createContext(null);
export const AuthContextProvider = ({ children }) => {
  const Navigate = useNavigate()
  const { loadOrg } = useContext(AppContext);
  const isAuthenticated = async () => {
    // const storageKey = local__storage_key + partyId;
    const storageKey = local__storage_key;
    if (storageKey in localStorage) {
      let user = localStorage.getItem(local__storage_key)
      if(user && user !== "undefined"){
        let userJSON = JSON.parse(user)
        const {conv_id, party_id} = userJSON
        const res = await axios.get(AppSettings.BASE_URL + "/party/"+party_id+"/chat/"+conv_id+"/user" );
        if (res && res.data && res.data.done) {
          return true
        } else{
          return false
        }
      }
    }
    return false;
  };
  const isAuthTokenReceived = () => {
    // const storageKey = local__storage_key + partyId;
    const storageKey = local__storage_key;
    if (storageKey in localStorage) {
      let data = localStorage.getItem(storageKey)
      if(data && data !=="undefined"){
        return true;
      }
    }
    return false;
  };
  const isUserDetailReceived = () => {
    // const storageKey = local__storage_key + partyId;
    const storageKey = local__storage_key;
    if (storageKey in localStorage) {
      let data = localStorage.getItem(storageKey)
      if(data && data !=="undefined"){
        return true;
      }
    }
    return false;
  };
  const isAuthenticatedSession = async () => {
    // const storageKey = local__storage_key + partyId;
    const storageKey = local__storage_key;
    if (storageKey in sessionStorage) {
      let user = sessionStorage.getItem(local__storage_key)
      if(user && user !=="undefined"){
        let userJSON = JSON.parse(user)
        const {conv_id, party_id} = userJSON
        const res = await axios.get(AppSettings.BASE_URL + "/party/"+party_id+"/chat/"+conv_id+"/user" );
        if (res && res.data && res.data.done) {
          localStorage.setItem(local__storage_key, sessionStorage.getItem(local__storage_key));
          return true;
        } else{
          return false
        }
      }
    }
    return false;
  };
  const isAuthTokenReceivedSession = () => {
    // const storageKey = local__storage_key + partyId;
    const storageKey = local__storage_key;
    if (storageKey in sessionStorage) {
      let data = sessionStorage.getItem(storageKey)
      if(data && data !=="undefined"){
        return true;
      }
    }
    return false;
  };
  const isUserDetailReceivedSession = () => {
    // const storageKey = local__storage_key + partyId;
    const storageKey = local__storage_key;
    if (storageKey in sessionStorage) {
      let data = sessionStorage.getItem(storageKey)
      if(data && data !=="undefined"){
        return true;
      }
    }
    return false;
  };
  const getAuthToken = () => {
    if (isAuthTokenReceived()) {
      // return localStorage.getItem(local__storage_token_key + partyId);
      return localStorage.getItem(local__storage_token_key);
    }
    else if (isAuthTokenReceivedSession()){
      localStorage.setItem(local__storage_token_key, sessionStorage.getItem(local__storage_token_key));
      return JSON.parse(sessionStorage.getItem(local__storage_token_key));
    }
    return null;
  };
  const getUserDetail = () => {
    if (isUserDetailReceived()) {
      // return JSON.parse(localStorage.getItem(local__storage_key + partyId));
      return JSON.parse(localStorage.getItem(local__storage_key));
    }
    else if (isUserDetailReceivedSession()){
      localStorage.setItem(local__storage_key, sessionStorage.getItem(local__storage_key));
      return JSON.parse(sessionStorage.getItem(local__storage_key));
    }
    return null;
  };
  const getAuthHeaderString = async () => {
    let authString = "";
    if (await isAuthenticated()) {
      authString = "Bearer " + getAuthToken();
    }
    return authString;
  };

  const setUserDetail = (user) => {
    // localStorage.setItem(local__storage_key + partyId, JSON.stringify(user));
    if(user){  
      localStorage.setItem(local__storage_key, JSON.stringify(user));
      sessionStorage.setItem(local__storage_key, JSON.stringify(user));
    }
  };
  const setToken = (token) => {
    // localStorage.setItem(local__storage_token_key + partyId, token);
    if(token){
      localStorage.setItem(local__storage_token_key, token);
      sessionStorage.setItem(local__storage_token_key, token);
    }
  };
  const setLoginDetail = (data) => {
    const { token, user } = data;

    setUserDetail(user);
    setToken(token);
  };
  const getUserName = () => {
    const user = getUserDetail();
    return user?.name;
  };
  const logOut = () => {
    localStorage.removeItem(local__storage_key)
    localStorage.removeItem(local__storage_token_key)
    sessionStorage.removeItem(local__storage_key)
    sessionStorage.removeItem(local__storage_token_key)
    Navigate("/login")
  };
  return (
    <AuthContext.Provider
      value={{
        getAuthHeaderString,
        isAuthenticated,
        isAuthenticatedSession,
        setLoginDetail,
        getUserDetail,
        getUserName,
        getAuthToken,
        logOut,
      }}
    >
      {loadOrg ? children : ""}
    </AuthContext.Provider>
  );
};
