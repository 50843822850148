import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import AppSettings from "../../Settings/AppSettings";
import { useNavigate } from "react-router-dom";

export const AppContext = createContext(null);
export const AppContextProvider = ({ children }) => {
  const [partyId, setPartyId] = useState(null);
  const [partyTypeId, setPartyTypeId] = useState(null);
  const [partyDetails, setPartyDetials] = useState(null);
  const [partyName, setPartyName] = useState(null);
  const [context, setContext] = useState(null);
  const [loadOrg, setLoadOrg] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loadOrg) {
      get_party_detail();
    }
  }, []); // eslint-disable-line

  const get_party_detail = async () => {
    try {
      const res = await axios.get(AppSettings.BASE_URL + "/party");
      setLoadOrg(true);
      if (res && res.data && res.data.done) {
        const party = res.data.data;
        setPartyDetials(party);
        setPartyId(party.party_id);
        setPartyTypeId(party.party_type_id);
        setPartyName(party.name)
      } else {
        // window.location.replace('https://client.myhotelchats.com/');
        navigate("/partyinvalid", { replace: true });
      }
    } catch (err) {
      navigate("/partyinvalid", { replace: true });
      console.log(err);
    }
  };
  return (
    <AppContext.Provider
      value={{ partyDetails, partyId, loadOrg, partyTypeId, partyName, context, setContext }}
    >
      {children}
    </AppContext.Provider>
  );
};
