import React, { memo } from 'react'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { areEqual } from 'react-window';
const DocumentViewer = memo(({uri,fileType, height,width}) => {
    const docs = [
        { uri, fileType}
      ];
      return (<>
        {fileType.includes('video') ? 
            <video controls style={{width: width??300, height: height??300, maxHeight:300,maxWidth:300}}>
            <source src={uri} type={fileType} />
            Your browser does not support the video tag.
          </video>
          :
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} style={{width: width??300, height: height??300, maxHeight:300,maxWidth:300}} config={{
              header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: true
              }
            }}/>
        }
         </>);
}, areEqual);

export default DocumentViewer
