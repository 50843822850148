import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ServiceContext } from "../Context/ServiceContext";
import { AuthContext } from "../Context/AuthContext";
import { CSSContext } from "../Context/CSSContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import Notification from "./Notification";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import './Login.css'


const Login = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [initialMessage, setInitialMessage] = useState("")
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [initialMessageError, setInitialMessageError] = useState("")
  const [requireEmail, setRequireEmail] = useState(false);
  const [requireInitialMessage, setRequireInitialMessage] = useState(false);
  const [complianceMessage, setComplianceMessage] = useState(false);
  const [requirePhone, setRequirePhone] = useState(false);
  const [submit, setSubmit] = useState(true);
  const [submitMessage, setSubmitMessage] = useState("You will receive a response by sms");
  const [loaded, setLoaded] = useState(false)
  const [smsChat, setSmsChat] = useState(false)
  const { doPost, doGet } = useContext(ServiceContext);
  const { setLoginDetail } = useContext(AuthContext);
  const { openingScreenBackgroundColor, openingScreenForegroundColor } = useContext(CSSContext);
  const { sendNotification } = Notification();
  const [searchParams] = useSearchParams();
  const redirectURL = searchParams.get("redirectURL") ?? "/";
  const Navigate = useNavigate();
  const [content , setContent] = useState("");
  const [avatar , setAvatar] = useState("");
  // const anonumous_login = async() => {
  //   const res = await doPost("/chat_anonymous_login", {}, false);
  //   if (res?.data) {
  //     setLoginDetail(res.data);
  //     Navigate(redirectURL, { replace: true });
  //   } else {
  //     if(res?.message){
  //       sendNotification({ msg: res.message, variant: "error" });
  //     }
  //     else{
  //       sendNotification({ msg: "Error Occured", variant: "error" });
  //     }
  //   }
  // }
  const  getComplementaryColor = (color) =>{
    // Remove '#' if present
    color = color.replace('#', '');
  
    // Convert color to RGB
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
  
    // Calculate complementary color
    const compR = 255 - r;
    const compG = 255 - g;
    const compB = 255 - b;
  
    // Convert complementary color to hexadecimal format
    const compColor = `#${compR.toString(16).padStart(2, '0')}${compG.toString(16).padStart(2, '0')}${compB.toString(16).padStart(2, '0')}`;
  
    return compColor;
  }
  const getDarkerShade = (color, percent)=> {
    // Remove '#' if present
    color = color.replace('#', '');
  
    // Convert color to RGB
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);
  
    // Calculate darker shade
    r = Math.floor(r * (100 - percent) / 100);
    g = Math.floor(g * (100 - percent) / 100);
    b = Math.floor(b * (100 - percent) / 100);
  
    // Convert darker shade back to hexadecimal format
    const darkerShade = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  
    return darkerShade;
  }
  const [ buttonColor, setButtonColor ] = useState(getComplementaryColor(openingScreenBackgroundColor))
  const [ buttonDarkColor, setButtonDarkColor ] = useState(getDarkerShade(buttonColor,10))
  useEffect(()=>{
    if(openingScreenBackgroundColor){
      setButtonColor(getComplementaryColor(openingScreenBackgroundColor))
    }
  },[openingScreenBackgroundColor]) // eslint-disable-line
  useEffect(()=>{
    if(buttonColor){
      setButtonDarkColor(getDarkerShade(buttonColor,10))
    }
  },[buttonColor]) // eslint-disable-line
  const load_party_avatar = async () =>{
    const res = await doGet("/welcome/message", false);
      if (res?.data) {
        
        const formattedMessage = res.data.welcome_message.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));
        setContent(formattedMessage)
        setAvatar(res.data.icon_url)
        setRequireEmail(res.data.email)
        setRequirePhone(res.data.phone)
        setRequireInitialMessage(res.data.initial_message)
        setComplianceMessage(res.data.compliance_message)
        setLoaded(true)
        setSmsChat(res.data.sms_chat)
      }
      else{
        setContent(`I'm your AI Assistant, Andrea. I talk like a human, well almost!
It will be a pleasure to chat with you about our hotel, give recommendations and to escalate any of your requests to the right people in the hotel.`)
        setAvatar("https://client.myhotelchats.com/talk.png")
        setLoaded(true)
      }
  }
  useEffect(()=>{
    // anonumous_login()
    load_party_avatar()
  },[]) // eslint-disable-line
  const isValidEmail=(email)=> {
      // Regular expression for a basic email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
      // Test the email against the regex
      return emailRegex.test(email);
  }
  const isValidPhoneNumberWithCountryCode=(phoneNumber)=> {
      // Regular expression for a phone number with country code
      const phoneRegex = /^\d{1,3}\d{10}$/;

      // Test the phone number against the regex
      return phoneRegex.test(phoneNumber);
  }
  

  const submithandler = async (e) => {
    if (name && (!requireEmail || (isValidEmail(email))) 
    && (!requirePhone || isValidPhoneNumberWithCountryCode(phone))
    && (!requireInitialMessage || initialMessage)) {
      const input = {
        name,
        email,
        phone: phone? ("+" + phone): null,
        initial_message: initialMessage
      };
      const res = await doPost("/chat_anonymous_login", input, false);
      if (res?.data) {
        setLoginDetail(res.data);
        Navigate(redirectURL, { replace: true });
      } else {
        if(res?.message){
          sendNotification({ msg: res.message, variant: "error" });
        }
        else{
          sendNotification({ msg: "Error Occured", variant: "error" });
        }
      }
    }
    else{
      if(!name){
        setNameError(true);
      }
      if(!isValidPhoneNumberWithCountryCode(phone)){
        setPhoneError(true);
      }
      if(!isValidEmail(email)){
        setEmailError(true);
      }
      if(!initialMessage){
        setInitialMessageError(true);
      }
    }
  };
  const submitSmsHandler = async (e) => {
    if (name && (!requireEmail || (isValidEmail(email))) 
    && (!requirePhone || isValidPhoneNumberWithCountryCode(phone))
    && (!requireInitialMessage || initialMessage)) {
      const input = {
        name,
        email,
        phone: phone? ("+" + phone): null,
        initial_message: initialMessage
      };
      const res = await doPost("/initiate_sms_chat", input, false);
      if (res?.data) {
        setSubmit(false)
        setSubmitMessage(res.data)
      } else {
        if(res?.message){
          sendNotification({ msg: res.message, variant: "error" });
        }
        else{
          sendNotification({ msg: "Error Occured", variant: "error" });
        }
      }
    }
    else{
      if(!name){
        setNameError(true);
      }
      if(!isValidPhoneNumberWithCountryCode(phone)){
        setPhoneError(true);
      }
      if(!isValidEmail(email)){
        setEmailError(true);
      }
      if(!initialMessage){
        setInitialMessageError(true);
      }
    }
  }
  return (
    <>  
      {
        smsChat ?
          <>
            <Box sx={{
                display:'block',
                background: "#fff",
                width: "100%",
                overflow: "scroll",
                height:"100vh",
                boxShadow:"0 0 20px rgb(0 0 0 / 22%)"}}>
              <Box 
                sx={{
                  background:"#3074dc",
                  height: "67px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color:"#fff",
                  fontWeight: "500"
                }}
              >
              <img src={avatar} alt="avatar" style={{height:"50px",width:"50px",marginRight:"10px"}}></img>
              Get Quick Response Via Text
              </Box>
              <Box sx={{
                padding: "15px",
                background: "#f5f5f7",
                overflow: "hidden",
                overflowY: "auto",
                height:"calc(99% - 87px)",
              }}
              >
                <Box sx={{
                  width:"400px",
                  overflow: "hidden",
                  overflowY: "auto",
                }}>
                  <Box sx={{

                    background: "#e4e9f0",
                    padding: "15px",
                    borderRadius: "17px",
                    fontSize: "13px",
                    borderBottomLeftRadius: 0,
                    marginBottom: "20px",
                    boxShadow: "4px 0px 10px rgb(0 0 0 / 2%)"
                  }}
                  >
                      {content}
                  </Box>

                  <Box sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "20px",
                    }}
                  >
                    <Box sx={{

                      background: "#fff",
                      minHeight: "80px",
                      width: "90%",
                      padding:"20px",
                      borderRadius: "17px",
                      borderBottomRightRadius: 0,
                      boxShadow: "4px 0px 10px rgb(0 0 0 / 12%)"
                    }}
                    >
                      <input 
                        type="text"
                        value={name}
                        disabled={!submit}
                        placeholder="Full Name"
                        onChange={(e) => {
                          setName(e.target.value);
                          setNameError(false);
                        }}
                        autoComplete="off"
                        style={{
                          padding: "10px",
                          margin: "5px 0px 5px 0px",
                          background: nameError?"red":"transparent",
                          border: "none",
                          borderBottom: "1px solid #dadada",
                          paddingLeft:"0px",
                          outline:"none",
                          width: "100%",
                          color:"#757171",
                          marginBottom: "15px",
                        }}
                      />
                      {requireEmail && 
                          <input 
                            type="text"
                            value={email}
                            placeholder="Email"
                            disabled={!submit}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setEmailError(false);
                            }}
                            autoComplete="off"
                            style={{
                              padding: "10px",
                              margin: "5px 0px 5px 0px",
                              background: emailError?"red":"transparent",
                              border: "none",
                              borderBottom: "1px solid #dadada",
                              color:"#757171",
                              paddingLeft:"0px",
                              outline:"none",
                              width: "100%",
                              marginBottom: "15px",
                            }}
                          />
                      }
                      
                      <PhoneInput
                        country={'us'}
                        value={phone}
                        countryCodeEditable={false}
                        enableSearch={true}
                        disabled={!submit}
                        inputProps={{
                          name: 'phone',
                          autoComplete:"off",
                          style:{
                            padding: "10px",
                            margin: "5px 0px 5px 0px",
                            background: phoneError ? "red" : "transparent",
                            border: "none",
                            borderBottom: "1px solid #dadada",
                            borderRadius:0,
                            outline:"none",
                            width: "100%",
                            color:"#757171",
                            marginBottom: "15px",
                            paddingLeft:"60px",
                          }
                        }}
                        onChange={(val) => {
                          setPhone(val);
                          setPhoneError(false);
                        }}
                      />
                        
                      <textarea 
                        type="text"
                        value={initialMessage}
                        placeholder="Your Message"
                        disabled={!submit}
                        onChange={(e) => {
                          setInitialMessage(e.target.value);
                          setInitialMessageError(false);
                        }}
                        autoComplete="off"
                        style={{
                          padding: "10px",
                          margin: "5px 0px 5px 0px",
                          background: initialMessageError?"red":"transparent",
                          border: "none",
                          borderBottom: "1px solid #dadada",
                          paddingLeft:"0px",
                          outline:"none",
                          width: "100%",
                          color:"#757171",
                        }}
                        rows={3}
                      />
                      
                    </Box>
                    
                  </Box>
                  
                  
                  {submit && 
                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={submitSmsHandler}
                        sx={{color:"#fff", width: "50%",maxWidth: "400px",minWidth: "170px",background: "#3074dc",fontSize:"0.675rem",
                          "&:hover":{textDecoration: "none",backgroundColor: "#11418b",boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5"}}}
                      >
                        Submit
                      </Button>
                    </Box>
                  }
                  {!submit && 
                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box sx={{

                        background: "#fff",
                        // minHeight: "80px",
                        width: "90%",
                        padding:"20px",
                        borderRadius: "17px",
                        borderBottomLeftRadius: 0,
                        boxShadow: "4px 0px 10px rgb(0 0 0 / 12%)"
                      }}
                      >
                        
                        <Typography variant="body1" sx={{fontSize:'12px'}}>{submitMessage}</Typography>  
                      </Box>
                    </Box>
                  }
                  <Box sx={{padding: "15px 30px",fontSize: "11px"}}>
                    {complianceMessage}
                  </Box>  
                  <Box sx={{position:"fixed", left:"10px",bottom:"2px",opacity: ".6"}}>
                    <Typography variant="caption" sx={{fontSize:"0.60rem"}}>By Cordiant</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        :
        <Box
          sx={{
            // paddingTop:"20px",
            backgroundColor: openingScreenBackgroundColor,
            height:"100%",
            width:'100%',
            color:openingScreenForegroundColor,
            position:"absolute",
            // overflow:"hidden"
            overflowY:"scroll",
          }}
        >
          <Box 
            sx={{
              margin:"30px 50px 50px 50px"
            }}
          >
            {
              loaded && 
              <>
                <Box sx={{marginTop:"20px",marginBottom:"20px"}}>
                  <img src={avatar} alt="avatar" style={{height:"50px",width:"50px"}}></img>
                </Box>
                <Typography variant="body1"  >
                {content}
                </Typography >
                <Box
                  sx={{width:"60%",maxWidth: "400px",minWidth: "170px",}}
                >
                  <Box sx={{display: "none"}}>
                    <input type="text" id="PreventChromeAutocomplete" 
                      name="PreventChromeAutocomplete" autoComplete="address-level4" />
                  </Box>  
                  <input 
                    type="text"
                    value={name}
                    placeholder="Full Name"
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError(false);
                    }}
                    autoComplete="off"
                    style={{
                      padding: "10px 14px",
                      width: "80%",
                      maxWidth: "400px",
                      margin: "5px 0px 5px 0px",
                      background: nameError?"red":"transparent",
                      border: "none",
                      borderBottom: "2px solid",
                      borderColor:openingScreenForegroundColor,
                      color:openingScreenForegroundColor,
                      paddingLeft:"0px",
                      minWidth: "170px",
                      outline:"none",
                      '::placeholder': {
                        color:openingScreenForegroundColor,
                      }
                    }}
                  />
                  {requireEmail && 
                    <input 
                      type="text"
                      value={email}
                      placeholder="Email *"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError(false);
                      }}
                      autoComplete="off"
                      style={{
                        padding: "10px 14px",
                        width: "80%",
                        maxWidth: "400px",
                        margin: "5px 0px 5px 0px",
                        background: emailError?"red":"transparent",
                        border: "none",
                        borderBottom: "2px solid",
                        borderColor:openingScreenForegroundColor,
                        color: emailError?"red":openingScreenForegroundColor,
                        paddingLeft:"0px",
                        minWidth: "170px",
                        outline:"none",
                      }}
                    />
                  }
                  { requirePhone &&
                   <>
                   <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PhoneInput
                      country={'us'}
                      value={phone}
                      countryCodeEditable={false}
                      enableSearch={true}
                      inputProps={{
                        name: 'phone',
                        autoComplete:"off",
                        style:{
                          padding: "10px 14px 10px 55px",
                          width: "83%",
                          maxWidth: "400px",
                          margin: "5px 0px 5px 0px",
                          background: phoneError ? "red" : "transparent",
                          border: "none",
                          borderRadius: 0,
                          borderBottom: "2px solid",
                          borderColor:openingScreenForegroundColor,
                          color:openingScreenForegroundColor,
                          minWidth: "170px",
                          outline: "none",
                        }
                      }}
                      onChange={(val) => {
                        setPhone(val);
                        setPhoneError(false);
                      }}
                    />
                   </Box>
                 </>
                  }
                  {requireInitialMessage && 
                    <textarea 
                      type="text"
                      value={initialMessage}
                      placeholder="Your Message"
                      onChange={(e) => {
                        setInitialMessage(e.target.value);
                        setInitialMessageError(false);
                      }}
                      autoComplete="off"
                      style={{
                        padding: "10px 14px",
                        width: "80%",
                        maxWidth: "400px",
                        margin: "5px 0px 5px 0px",
                        background: initialMessageError?"red":"transparent",
                        border: "none",
                        borderBottom: "2px solid",
                        borderColor:openingScreenForegroundColor,
                        color: initialMessageError?"red":openingScreenForegroundColor,
                        paddingLeft:"0px",
                        minWidth: "170px",
                        outline:"none",
                      }}
                      rows={3}
                    />
                  }
                </Box>
                { 
                  requirePhone &&
                  <>
                  {/* <Typography variant="body"  sx={{color:"#fff",fontSize:'6px',margin: "-4px 0", lineHeight:'0.5 '}} > */}
                    <p style={{color:openingScreenForegroundColor,fontSize:'8px', lineHeight:'1',margin:0}}>
                    {complianceMessage}
                    </p>
                  {/* </Typography > */}
                    
                  </>
                }
                <br/>
                <Button
                  variant="contained"
                  onClick={submithandler}
                  sx={{color:openingScreenBackgroundColor, width: "50%",maxWidth: "400px",minWidth: "170px",background: buttonColor,fontSize:"0.675rem", marginTop:'10px',
                    "&:hover":{textDecoration: "none",backgroundColor: buttonDarkColor, boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5"}}}
                >
                  Start Conversation
                </Button>
                <Box sx={{position:"fixed", left:"10px",bottom:"2px",opacity: ".6"}}>
                  <Typography variant="caption" sx={{fontSize:"0.60rem"}}>By Cordiant</Typography>
                </Box>
              </>
            }
          </Box>
        </Box>
      }
    </>
  );
};

export default Login;
