// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./invalid_tenant_background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.invalidTenantDiv {\n  margin: 0; /* Remove default body margin */\n  padding: 0; /* Remove default body padding */\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* Replace 'path/to/your/image.png' with the actual path to your PNG image */\n  background-size: cover; /* Cover the entire viewport with the background image */\n  background-repeat: no-repeat; /* Prevent image repetition */\n  background-attachment: fixed; /* Fixed background */\n  /* Additional styles (optional) */\n  color: #fff !important; /* Set text color to white, or a color that contrasts well with your background */\n  top:0;\n  bottom:0;\n  position:fixed;\n  left:0;\n  right:0;\n}", "",{"version":3,"sources":["webpack://./src/Components/Common/styles.css"],"names":[],"mappings":";AACA;EACE,SAAS,EAAE,+BAA+B;EAC1C,UAAU,EAAE,gCAAgC;EAC5C,yDAAwD,EAAE,4EAA4E;EACtI,sBAAsB,EAAE,wDAAwD;EAChF,4BAA4B,EAAE,6BAA6B;EAC3D,4BAA4B,EAAE,qBAAqB;EACnD,iCAAiC;EACjC,sBAAsB,EAAE,iFAAiF;EACzG,KAAK;EACL,QAAQ;EACR,cAAc;EACd,MAAM;EACN,OAAO;AACT","sourcesContent":["\n.invalidTenantDiv {\n  margin: 0; /* Remove default body margin */\n  padding: 0; /* Remove default body padding */\n  background-image: url('./invalid_tenant_background.png'); /* Replace 'path/to/your/image.png' with the actual path to your PNG image */\n  background-size: cover; /* Cover the entire viewport with the background image */\n  background-repeat: no-repeat; /* Prevent image repetition */\n  background-attachment: fixed; /* Fixed background */\n  /* Additional styles (optional) */\n  color: #fff !important; /* Set text color to white, or a color that contrasts well with your background */\n  top:0;\n  bottom:0;\n  position:fixed;\n  left:0;\n  right:0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
