const APP_ENVIRONMENT = "DEV";
const APP_NAME = "Concierge Frontend";
const BASE_URL = process.env.REACT_APP_BASE_URL

const AppSettings = {
    APP_ENVIRONMENT: APP_ENVIRONMENT,
    APP_NAME: APP_NAME,
    BASE_URL: BASE_URL
}
export default AppSettings
