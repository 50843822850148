import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { Fragment, useEffect, useState } from "react";

const Notification = () => {
  const [conf, setConf] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (key) => (
    <Fragment>
      <IconButton
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <Close />
      </IconButton>
    </Fragment>
  );
  const add_snackbar = ()=>{
    let variant = "info";
    if (conf.variant) {
      variant = conf.variant;
    }
    enqueueSnackbar(conf.msg, {
      variant: variant,
      autoHideDuration: 3000,
      action,
    });
  }
  useEffect(() => {
    if (conf?.msg) {
        add_snackbar()
    }
  }, [conf]);// eslint-disable-line  

  return { sendNotification:setConf };
};

export default Notification;
