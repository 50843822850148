import './styles.css';
const TenantInvalid = () => {
  return (
    <div className="invalidTenantDiv">
      <h1>Houston, we have a problem! It seems you've landed on the wrong planet.</h1>

      <h1>Please recheck the URL or navigate to <a href="www.cordiant.com">www.cordiant.com.</a></h1>
    </div>
  );
};
export default TenantInvalid;