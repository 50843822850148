import { ListItem, Paper } from '@mui/material'
import React from 'react'
import Skeleton from '@mui/material/Skeleton';

const Typing = ({ style, index }) => {
  return (
    <ListItem
      disableGutters
      sx={{ justifyContent: "left" }}
      style={style}
      component="div"
      key={index}
    >
      <Paper
        elevation={3}
        sx={{
          width: "150px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Skeleton variant="rounded" width={250} height={25} />
      </Paper>
    </ListItem>
  )
}

export default Typing
