import { FileDownload } from "@mui/icons-material";
import { IconButton, ListItem, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { saveAs } from "file-saver";
import DocumentViewer from "../../Common/DocumentViewer";
const RecieveDocument = ({ style, data, index, height, width }) => {
  function addBoldTags(text) {
    // Use regular expression to match all occurrences of **...**
    const regex = /\*\*(.*?)\*\*/g;
    
    // Replace each match with <b>...<b>
    return text.replace(regex, "<b>$1</b>");
  }
  return (
    <ListItem
      disableGutters
      sx={{ justifyContent: "flex-start" }}
      style={style}
      component="div"
      key={index}
      data-msg_id={data.msg_id}
    >
      <Paper
        elevation={3}
        sx={{
          // maxWidth: {
          //   xs: "calc(85% - 8px)",
          //   sm: "calc(56% - 8px)",
          //   md: "calc(65% - 8px)",
          //   lg: "calc(70% - 8px)",
          // },
          maxWidth:"calc(80%)",
          borderRadius:'14px',
          marginLeft:'2px'
        }}
      >
        <Box sx={{ position: "relative", p: 1 }}>
          <Box sx={{ height: width>450?300:`calc(.5*${width})`, width: width>450?300:`calc(.5*${width})` ,maxWidth:300,maxHeight:300  }}>
            { 
              data.attachment_type ==="story_mention"
              ?
              <>
                {data.attachment_redirect ?
                  <a href={data.attachment_redirect} target="_blank" rel="noopener noreferrer">
                    <img src={data.attachment_url} alt="Story" />
                  </a>:
                  <img src={data.attachment_url} alt="Story" />
                }
              </>
              :
              <>
              {data.attachment_redirect ?
                <a href={data.attachment_redirect} target="_blank" rel="noopener noreferrer">
                  <DocumentViewer
                    uri={data.attachment_url}
                    fileType={data.attachment_type}
                    height={width>450?300:`calc(.5*${width})` }
                    width={width>450?300:`calc(.5*${width})` }
                  />
                </a>
                :<DocumentViewer
                uri={data.attachment_url}
                fileType={data.attachment_type}
                height={width>450?300:`calc(.5*${width})` }
                width={width>450?300:`calc(.5*${width})` }
              />
              }
            </>
            }
          </Box>
          {data.body && (
            <Box>
              { data.attachment_redirect ?
                <a href={data.attachment_redirect} target="_blank" rel="noopener noreferrer">
                  <Typography sx={{ overflowWrap: "break-word", textAlign: "center" }} variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: addBoldTags(data.body.replace(/\n/g, "<br>"))
                    }}
                  >
                  </Typography>
                </a>
                :
                <Typography sx={{ overflowWrap: "break-word", textAlign: "center" }} variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: addBoldTags(data.body.replace(/\n/g, "<br>"))
                  }}
                >
                </Typography>
              }
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop:"10px"}}>
            <Typography variant="faded" sx={{fontSize:'13px'}}>
              {new Date(data.created_at).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </Typography>
          </Box>
          { data.attachment_type ==="story_mention" ? <></> :
            <IconButton
              sx={{ position: "absolute", bottom: "-5px", left: "-5px" }}
              onClick={() =>
                saveAs(
                  data.attachment_url,
                  data.attachment_name ?? data.attachment_url.split("/").pop()
                )
              }
            >
              <FileDownload />
            </IconButton>
          }
        </Box>
      </Paper>
    </ListItem>
  );
};

export default RecieveDocument;
