import React, { useContext, useState } from "react";
import { Avatar, Button, Grid, Paper, TextField } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { ServiceContext } from "../Context/ServiceContext";
import Utils from "../../Common/Utils";
import { useNavigate } from "react-router-dom";
import Notification from "./Notification";
const Login = () => {
  const { sendNotification } = Notification();
  const Navigate = useNavigate();
  const { doPost } = useContext(ServiceContext);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [rePasswordError, setRePasswordError] = useState("");
  const [rePassword, setRePassword] = useState("");

  const submithandler = async (e) => {
    let error = false;
    if (!name) {
      error = true;
      setNameError("Field cannot be empty!!!");
    }
    if (!email) {
      error = true;
      setEmailError("Field cannot be empty!!!");
    }
    if (!Utils.validateEmail(email)) {
      error = true;
      setEmailError("Invalid Email");
    }
    if (!password) {
      error = true;
      setPasswordError("Field cannot be empty!!!");
    }
    if (!rePassword) {
      error = true;
      setRePasswordError("Field cannot be empty!!!");
    }
    if (password !== rePassword) {
      error = true;
      setRePasswordError("Password not matching!!");
    }

    if (!error) {
      const input = {
        email,
        password,
        name,
      };
      const res = await doPost("/chat_signup", input, false);
      if (res?.done) {
        Navigate("/login", { replace: true });
      } else {
        if(res?.message){
          sendNotification({ msg: res.message, variant: "error" });
        }
        else{
          sendNotification({ msg: "Error Occured", variant: "error" });
        }
      }
    }
  };
  return (
    <>
      <Grid>
        <Paper
          elevation={10}
          style={{
            padding: 30,
            width: 350,
            margin: "20px auto",
          }}
        >
          <Grid align="center">
            <Avatar style={{ backgroundColor: "#BDBDBD" }}>
              <LockOutlined />
            </Avatar>
            <h2>Sign Up</h2>
          </Grid>
          <TextField
            label="Name"
            id="name"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError("");
            }}
            error={nameError ? true : false}
            helperText={nameError}
            fullWidth
            required
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="email"
            id="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
            error={emailError ? true : false}
            helperText={emailError}
            fullWidth
            required
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="password"
            id="password"
            placeholder="Enter Password"
            type="password"
            value={password}
            error={passwordError ? true : false}
            helperText={passwordError}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError("");
            }}
            fullWidth
            required
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Repeat Password"
            id="rePassword"
            placeholder="Enter Password again"
            type="password"
            value={rePassword}
            error={rePasswordError ? true : false}
            helperText={rePasswordError}
            onChange={(e) => {
              setRePassword(e.target.value);
              setRePasswordError("");
            }}
            fullWidth
            required
            style={{ marginBottom: 20 }}
          />
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={submithandler}
          >
            Sign up
          </Button>
        </Paper>
      </Grid>
    </>
  );
};

export default Login;
