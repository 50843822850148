import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Context/AuthContext";
import { AppContext } from "../Context/AppContext";
import MessageList from "./MessageList";
import { ServiceContext } from "../Context/ServiceContext";
import Notification from "../Common/Notification";

const OmniLayout = () => {
  const { doPost } = useContext(ServiceContext);
  const { getUserDetail, setLoginDetail } = useContext(AuthContext);
  const { partyId } = useContext(AppContext);
  const [selected_conv, setSelected_conv] = useState(null);
  const { sendNotification } = Notification();
  useEffect(() => {load_conversations()}, []) //eslint-disable-line
  const load_conversations = async() => {
    let user = getUserDetail()
    if(user?.party_id === partyId){
      setSelected_conv(user)
    }
    else{
      const res = await doPost("/admin/chat/refresh_token", {}, true);
      if (res?.data) {
        setLoginDetail(res.data);
        setSelected_conv(res.data.user)
      } else {
        sendNotification({ msg: "Error Occured", variant: "error" });
      }
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          // height: {
          //   xs: `calc(100vh - 50px)`,
          //   sm: `calc(100vh - 64px)`,
          // },
        }}
      >
        <MessageList
          selected_conv={selected_conv}
        />
      </Box>
    </>
  );
};

export default OmniLayout;
