import { PriorityHigh, VisibilityOutlined } from "@mui/icons-material";
import { ListItem, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

const SendMessage = ({ data, style, index }) => {
  const [text, setText] = useState(data.body) //eslint-disable-line
  const [comp, setComp] = useState(<></>)
  // function addNewlineAfterThirdPeriod(paragraph) {
  //   let count = 0;
  //   let result = '';
    
  //   for (let i = 0; i < paragraph.length; i++) {
  //     const char = paragraph.charAt(i);
      
  //     if (char === '.') {
  //       count++;
        
  //       if (count % 3 === 0) {
  //         result += '. </br>'; // Add a newline character after the third period
  //         continue;
  //       }
  //     }
      
  //     result += char;
  //   }
    
  //   return result;
  // }
  function addBoldTags(text) {
    // Use regular expression to match all occurrences of **...**
    const regex = /\*\*(.*?)\*\*/g;
    
    // Replace each match with <b>...<b>
    return text.replace(regex, "<b>$1</b>");
  }
  useEffect(()=>{
    if(text){
      let unproccessed_text=text.replace(/\n/g, " <br/> ");
      var urlRegex = /(https?:\/\/[^\s()[\],]+)/g;
      let matches = unproccessed_text.match(urlRegex)
      let processed_text = unproccessed_text
      if (matches && matches.length>0){

        const uniqueMatches = [...new Set(matches)];
        uniqueMatches.sort((a, b) => b.length - a.length);
        let filteredMatch = uniqueMatches.map((a)=>{
          return a.replace(/(^[.\s]+)|([.\s]+$)/g, '')
        })
        for(let arr in filteredMatch){
          processed_text = processed_text.replace(filteredMatch[arr],  'ENCODED_URL'+arr)
        }
        for(let i=(filteredMatch.length-1);i>=0;i--){
          processed_text = processed_text.replace('ENCODED_URL'+i,  '<a href="'+filteredMatch[i]+'" target="_blank"> '+filteredMatch[i]+' </a>')
        }
      }
      // const phoneRegex = /(\d{3})\D*(\d{3})\D*(\d{4})/g;
      // processed_text = processed_text.replace(phoneRegex, '<a href="tel:$&" target="_blank">$&</a>');
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;
      processed_text = processed_text.replace(emailRegex, '<a href="mailto:$&" target="_blank">$&</a>');
      processed_text = addBoldTags(processed_text)
      setComp(<Typography  sx={{ overflowWrap: "break-word" , color :"white"}} variant="body2" dangerouslySetInnerHTML={{__html: processed_text}}/>)
      // setComp(<textarea value={processed_text} 
      //   style={{resize: "none",
      //   border: "none",
      //   width: "auto",
      //   height: "auto",
      //   boxSizing: "border-box"}}
      //   disabled={true} ></textarea>)
    }
  },[text])
  return (
    <>
    { data && <ListItem
      disableGutters
      sx={{ justifyContent: "flex-end" }}
      style={style}
      component="div"
      key={index}
      data-msg_id={data.msg_id}
    >
      <Paper
        elevation={3}
        sx={{
          // maxWidth: {
          //   xs: "calc(85% - 8px)",
          //   sm: "calc(56% - 8px)",
          //   md: "calc(65% - 8px)",
          //   lg: "calc(70% - 8px)",
          // },
          maxWidth:"calc(80%)",
          borderRadius:'14px',
          backgroundColor:"#007AFF"
        }}
      >
        <Box sx={{ position: "relative", p: 1 }}>
          <Box>
          {/* <Typography  sx={{ overflowWrap: "break-word" }} variant="body2">{data.body}</Typography> */}
          {comp}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="faded" sx={{fontSize:'13px', color :"white"}}>
              {new Date(data.created_at).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </Typography>
            {data.status === "read" && (
              <>
                <Box sx={{ p: 1 }}></Box>
                <VisibilityOutlined />
              </>
            )}
            {data.status === "failed" && (
              <>
                <Box sx={{ p: 1 }}></Box>
                <PriorityHigh />
              </>
            )}
          </Box>
        </Box>
      </Paper>
    </ListItem>}
    </>
  );
};

export default SendMessage;
