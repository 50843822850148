import React, { createContext, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { AppContext } from "./AppContext";
import AppSettings from "../../Settings/AppSettings";
import Notification from "../Common/Notification";
import { useNavigate } from "react-router-dom";
export const ServiceContext = createContext(null);

export const ServiceContextProvider = ({ children }) => {
  const Navigate = useNavigate()
  const { partyId,partyTypeId } = useContext(AppContext);
  const { sendNotification } = Notification();
  const { getAuthHeaderString } = useContext(AuthContext);
  const doPost = async (
    url,
    inputdata = null,
    auth = false,
    isFileUpload = false
  ) => {
    let retObj = {};
    try {
      const authString = auth ? getAuthHeaderString() : null;
      var formBody = inputdata;
      if (!isFileUpload && inputdata !== null) {
        inputdata.party_id= partyId;
        if(!inputdata?.party_type_id){
          inputdata.party_type_id= partyTypeId;
        }
        formBody = JSON.stringify(inputdata);
      }

      var headersObj = {
        Accept: "application/json",
        Authorization: authString,
      };
      if(!isFileUpload){
        headersObj["Content-Type"] = "application/json"
      }
      // if (isFileUpload) {
      //   headersObj = {
      //     Authorization: authString,
      //   };
      // }
      const rawResponse = await fetch(AppSettings.BASE_URL + url, {
        method: "POST",
        headers: headersObj,
        body: formBody,
      });
      retObj = await rawResponse.json();
    } catch (error) {
      retObj = { done: false, message: error.message, data: null };
    } finally {
    }
    retObj = check_token_invalid(retObj);
    return retObj;
  };

  const doGet = async (url, auth = false) => {
    let retObj = {};
    try {
      const authString = auth ? getAuthHeaderString() : "";

      const rawResponse = await fetch(AppSettings.BASE_URL + url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authString,
        },
      });
      retObj = await rawResponse.json();
    } catch (error) {
      retObj = { done: false, message: error.message, data: null };
    } finally {
    }
    retObj = check_token_invalid(retObj);
    return retObj;
  };
  const doPut = async (
    url,
    inputdata = null,
    auth = false,
    isFileUpload = false
  ) => {
    let retObj = {};
    try {
      const authString = auth ? getAuthHeaderString() : "";
      var formBody = inputdata;
      if (!isFileUpload && inputdata !== null) {
        inputdata.party_id= partyId;
        if(!inputdata?.party_type_id){
          inputdata.party_type_id= partyTypeId;
        }
        formBody = JSON.stringify(inputdata);
      }

      var headersObj = {
        Accept: "application/json",
        Authorization: authString,
      };
      if(!isFileUpload){
        headersObj["Content-Type"] = "application/json"
      }
      // if (isFileUpload) {
      //   headersObj = {
      //     Authorization: authString,
      //   };
      // }
      const rawResponse = await fetch(AppSettings.BASE_URL + url, {
        method: "PUT",
        headers: headersObj,
        body: formBody,
      });
      retObj = await rawResponse.json();

    } catch (error) {
      retObj = retObj = { done: false, message: error.message, data: null };
    } finally {
    }
    retObj = check_token_invalid(retObj);
    return retObj;
  };
  const doDelete = async (url, auth = false) => {
    let retObj = {};
    try {
      const authString = auth ? getAuthHeaderString() : "";

      const rawResponse = await fetch(AppSettings.BASE_URL + url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: authString,
        },
      });
      retObj = await rawResponse.json();
    } catch (error) {
      retObj = { done: false, message: error.message, data: null };
    } finally {
    }
    retObj = check_token_invalid(retObj);
    return retObj;
  };
  const check_token_invalid = (ret) => {
      if(typeof(ret.data) ==="string" && ret.data === "Not authenticated"){
        sendNotification({ msg: "Please Login", variant: "error" })
        Navigate("/login?redirectURL="+window.location.pathname,{replace:true})
      }
    return ret;
  };
  return (
    <ServiceContext.Provider value={{ doPost, doGet, doDelete, doPut }}>
      {children}
    </ServiceContext.Provider>
  );
};
