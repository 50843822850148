import { io } from "socket.io-client";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import { AppContext } from "./AppContext";
import AppSettings from "../../Settings/AppSettings";
import { Outlet, useNavigate } from "react-router-dom";
import Notification from "../Common/Notification";
export const SocketContext = createContext(null);
export const SocketContextProvider = () => {
  const Navigate = useNavigate();
  const { sendNotification } = Notification();
  const { getAuthToken } = useContext(AuthContext);
  const { partyId } = useContext(AppContext);
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    const token = getAuthToken();
      const socket_connect = io.connect(
        AppSettings.BASE_URL + "/chat",
        {
          auth: {
            token: token,
            party:partyId,
          },
        }
      );
      socket_connect.on('connect', function() { 
        setSocket(socket_connect)
      });
      socket_connect.on("connect_error", (err) => {
        sendNotification({ msg: err.message, variant: "error" });
        setTimeout(() => {
          Navigate("/login", { replace: true });
        }, 2000);
      });
      socket_connect.on("disconnect", (data) => {
        setSocket(null);
        if(data==="transport close"){
          Navigate("/login", { replace: true });
        }
      });
      return () => socket_connect.disconnect();
  }, []); // eslint-disable-line
  const socketConnected = () => {
    return socket?.connected;
  };
  return (
    <SocketContext.Provider value={{ socket, socketConnected }}>
      {socket ? <Outlet /> : <></>}
    </SocketContext.Provider>
  );
};
