import React from 'react'

const Logout = () => {
  return (
    <div>
      <h2>LOGOUT</h2>
    </div>
  )
}

export default Logout
