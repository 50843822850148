import React, { useContext, useState } from "react";
import { ServiceContext } from "../Context/ServiceContext";
import Notification from "../Common/Notification";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
const ResetPassword = () => {
  const Navigate = useNavigate();
  const { doPost } = useContext(ServiceContext);
  const { sendNotification } = Notification();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const resetPasswordHandler = async () => {
    if (!password) {
        setPasswordError(true);
    } else if (!newPassword){
        setNewPasswordError(true)
    } else if (!confirmPassword || confirmPassword!==newPassword){
        setConfirmPasswordError(true)
    } else {
      const inputData = { password:password, newPassword:newPassword };
      let res = {};
      res = await doPost(`/admin/chat/reset_password`, inputData, true);
      
      if (res?.done) {
        sendNotification({ msg: "Successfully Updated", variant: "success" });
        Navigate("/");
      } else {
        if(res?.message){
          sendNotification({ msg: res.message, variant: "error" });
        }
        else{
          sendNotification({ msg: "Error Occured", variant: "error" });
        }
      }
    }
  };
  return (
    <>
    <Box p={2}>
      <Grid align="center">
        <Paper
          sx={{
            minWidth: 300,
            maxWidth: 500,
            margin: "20px auto",
          }}
        >
          <Box p={5}>
            <Typography variant="h5" style={{ marginBottom: 25 }}>
              Reset Password
            </Typography>
          <Box sx={{ display: "flex", float: "left" }}>
                <IconButton onClick={()=>{Navigate("/")}}>
                  <ArrowBack />
                </IconButton>
              </Box>
            <TextField
              label="Current Password"
              placeholder="Enter Current Password"
              type="password"
              value={password}
              error={passwordError}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError(false);
              }}
              helperText={!password ? "* Required" : ""}
              fullWidth
              style={{ marginBottom: 20,marginTop:10 }}
            />
            <TextField
              label="New Password"
              placeholder="Enter New Password"
              type="password"
              value={newPassword}
              error={newPasswordError}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setNewPasswordError(false);
              }}
              helperText={!newPassword ? "* Required" : ""}
              fullWidth
              style={{ marginBottom: 20,marginTop:10 }}
            />
            <TextField
              label="Confirm Password"
              placeholder="Confirm Password"
              type="password"
              value={confirmPassword}
              error={confirmPasswordError}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordError(false);
              }}
              helperText={!password ? "* Required" : ""}
              fullWidth
              style={{ marginBottom: 20,marginTop:10 }}
            />
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={resetPasswordHandler}
            >
              {"Reset Password"}
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Box>
    </>
  )
}

export default ResetPassword
