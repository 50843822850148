import React, { createContext, useEffect, useState, useContext } from "react";
import { AppContext } from "./AppContext";
import { ServiceContext } from "./ServiceContext";
export const CSSContext = createContext(null);

export const CSSContextProvider = ({ children }) => {
  const { loadOrg } = useContext(AppContext);
  const [openingScreenForegroundColor, setOpeningScreenForegroundColor] = useState("#ffffff")
  const [openingScreenBackgroundColor, setOpeningScreenBackgroundColor] = useState("#003366")
  const [titleBarBackgroundColor, setTitleBarBackgroundColor] = useState("#003366")
  const [titleBarForegroundColor, setTitleBarForegroundColor] = useState("#ffffff")
  const [chatInputPerimeterColor, setChatInputPerimeterColor] = useState("#000000")
  const [chatBackgroundImage, setChatBackgroundImage] = useState("https://conceirge-app.s3.amazonaws.com/bundled-js/chat-background.jpg")
  const [loaded, setLoaded] = useState(false)
  const { doGet } = useContext(ServiceContext);
  useEffect(() => {
    load_party_avatar()
    let viewportMetaTag = document.createElement('meta');

    // Set attributes for the meta tag
    viewportMetaTag.setAttribute('name', 'viewport');
    viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, interactive-widget=resizes-content');

    // Append the meta tag to the head element
    document.head.appendChild(viewportMetaTag);
  }, []); // eslint-disable-line
  const load_party_avatar = async () =>{
    const res = await doGet("/welcome/message", false);
    setLoaded(true)
      if (res?.data) {
        if(res.data.opening_screen_background_color){
          setOpeningScreenBackgroundColor(res.data.opening_screen_background_color)
        }
        if(res.data.opening_screen_foreground_color){
          setOpeningScreenForegroundColor(res.data.opening_screen_foreground_color)
        }
        if(res.data.title_bar_background_color){
          setTitleBarBackgroundColor(res.data.title_bar_background_color)
        }
        if(res.data.title_bar_foreground_color){
          setTitleBarForegroundColor(res.data.title_bar_foreground_color)
        }
        if(res.data.chat_input_perimeter_color){
          setChatInputPerimeterColor(res.data.chat_input_perimeter_color)
        }
        if(res.data.chat_background_url){
          setChatBackgroundImage(res.data.chat_background_url)
        }
      }
  }

   return (
  <CSSContext.Provider value={{ openingScreenBackgroundColor, titleBarBackgroundColor, titleBarForegroundColor, chatInputPerimeterColor, chatBackgroundImage, openingScreenForegroundColor }} >
      {( loadOrg && loaded ) ? children : ""}
    </CSSContext.Provider>
   );
};
