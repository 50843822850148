import {
  AttachFile,
  SendRounded,
  TextSnippetSharp,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import SendMessage from "./Components/SendMessage";
import RecievedMessage from "./Components/RecievedMessage";
import ShowDate from "./Components/ShowDate";
import SendDocument from "./Components/SendDocument";
import RecieveDocument from "./Components/RecieveDocument";
import { VariableSizeList, areEqual } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { SocketContext } from "../Context/SocketContext";
import { CSSContext } from "../Context/CSSContext";
import Typing from "./Components/Typing.jsx";
import "./css/conversation.module.css";
import { ServiceContext } from "../Context/ServiceContext";
import { AppContext } from "../Context/AppContext";

const MessageList = ({  selected_conv }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  const { socket } = useContext(SocketContext);
  const { chatInputPerimeterColor, chatBackgroundImage } = useContext(CSSContext);
  const { partyId, context, setContext } = useContext(AppContext);
  const { doGet } = useContext(ServiceContext);
  const [msgLoaded, setMsgLoaded] = useState(false);
  const [msgList, setMsgList] = useState();
  const [FormattedMsgList, setFormattedMsgList] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [placeholder, setPlaceholder] = useState("Enter Message");
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  

  const changeFileHandler = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedFile(file);
      setSelectedFileType(file.type);
      setSelectedFileName(file.name);
      setIsFilePicked(true);
    } else {
      setIsFilePicked(false);
    }
  };
  const handleModalClose = () => {
    setSelectedFile(null);
    setIsFilePicked(false);
    setSelectedFileType(null);
    setSelectedFileName(null);
  };
  const handleDocumentSend = () => {
    socket.emit("sendDocument", {
      conv_id: selected_conv?.conv_id,
      file: selectedFile,
      fileName: selectedFileName,
      fileType: selectedFileType,
    });
    handleModalClose();
  };
  const load_placeholder = async () => {
    const res = await doGet(`/party/${partyId}/conv/${selected_conv?.conv_id}/placeholder`, false);
    if (res?.data && res?.data?.placeholder) {
      setPlaceholder(res.data.placeholder)
    } else {
      setPlaceholder("Enter Message")
    }
  }
  const load_all_messages = (flag=false) => {
    socket.emit(
      "getAllMessageForConv",
      { conv_id: selected_conv?.conv_id, party_id: selected_conv?.party_id },
      (response) => {
        if (response?.done) {
          if (flag){
            response.data.push({type:"typing",data:"data"})
          }
          setMsgList(response.data);
          setMsgLoaded(true);
        }
      }
    );
    load_placeholder()
  };
  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); //eslint-disable-line
  useEffect(() => {
    setMsgLoaded(false);
    if (selected_conv) {
      load_all_messages();
      socket.on("messageUpdated", (conv_id) => {
        if (selected_conv?.conv_id === conv_id) {
          load_all_messages();
        }
      });
      socket.on("contextUpdated", (conv_id, context) => {
        if (selected_conv?.conv_id === conv_id) {
          setContext(context)
        }
      });
      socket.on("processingResponse",(conv_id)=>{
        if (selected_conv?.conv_id === conv_id) {
          load_all_messages(true);
        }
      })
      socket.on("messageReceived", (conv_id) => {
        if (selected_conv?.conv_id === conv_id) {
          load_all_messages();
        }
      });
    }
  }, [selected_conv]); //eslint-disable-line
  const formatingMsgList = () => {
    let format_msg = [];
    let newdate = new Date(msgList[0].created_at).toDateString();
    format_msg.push({ type: "padding", data: 20 });
    format_msg.push({ type: "date", data: newdate });
    let nextdate;
    for (let arr of msgList) {
      if(arr.type === "typing"){
        format_msg.push({type:"typing",data:"data"});
        continue; // eslint
      }
      nextdate = new Date(arr.created_at).toDateString();
      if (newdate !== nextdate) {
        format_msg.push({ type: "date", data: nextdate });
        newdate = nextdate;
      }
      if (!arr.send) {
        if (arr.attachment) {
          format_msg.push({ type: "sent_attachment", data: arr });
        } else {
          format_msg.push({ type: "sent_msg", data: arr });
        }
      } else {
        if (arr.attachment) {
          format_msg.push({ type: "receive_attachment", data: arr });
        } else {
          format_msg.push({ type: "receive_msg", data: arr });
        }
      }
    }
    format_msg.push({ type: "padding", data: 20 });
    setFormattedMsgList(format_msg);
  };
  useEffect(() => {
    if (msgList?.length > 0) {
      formatingMsgList();
    }
  }, [msgList]); //eslint-disable-line
  const handleKeyDown = (event) =>{
    if (event.key === 'Enter') {
      if (!event.shiftKey) {
        event.preventDefault();
        handleSendMessage()
      }
    }
  }
  const handleSendMessage = () => {
    if (inputMessage) {
      const data = {
        conv_id: selected_conv?.conv_id,
        msg: inputMessage,
      };
      socket.emit("sendTextMsg", data);
      setInputMessage("");
    } 
  };
  
  return (
    <Box
      sx={{
        flex: { xs: 1, sm: 2, md: 3, lg: 4 },
        display: { xs: "block", sm: "block" },
        width: {
          xs: "80%",
          sm: "46%",
          md: "55%",
          lg: "60%",
        },
      }}
    >
      {selected_conv ? (
        <Box
          sx={{
            backgroundImage: `url('${chatBackgroundImage}')`,
            width:`${window.innerWidth}px`,
            height:`calc(${viewportHeight}px - 64px)`
          }}
        >
          <Box id="message-box"
            sx={{
              position:"absolute",
              top: "65px",
              right: "0px",
              left: "0px",
              bottom: "46px",
              overflowY: "scroll",
              margin: " 0px 35px",
              overflowX: "hidden",
            }}
          >
            {msgLoaded && (
              <AutoSizer style={{}}>
                {({ height, width }) => (
                 <MessageListWindow
                    height={height}
                    width={width}
                    FormattedMsgList={FormattedMsgList}
                  />
                )}
              </AutoSizer>
            )}
          </Box>
          <Box
            sx={{
              backgroundColor:'transparent',
              position: "absolute",
              bottom: 0,
              height:"46px",
              right:"0px",
              left:"0px"
            }}
          >
          <Box
            sx={{
              opacity: 1,
              position: "absolute",
              bottom: 0,
              right: isMobile ? 35: { xs: 0, sm: "17%", md: "17%", lg: "17%" },
              left: isMobile ? 35:{ xs: 0, sm: "17%", md: "17%", lg: "17%" },
            }}
          >
            <Grid container justifyContent={"space-between"} sx={{ margin: 0 }}>
              <Grid item xs={1} sx={{}}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <IconButton
                    color="primary"
                    component="label"
                    disabled={!selected_conv}
                  >
                    {selected_conv?.channel === "google" ? (
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={changeFileHandler}
                      />
                    ) : (
                      <input
                        hidden
                        accept="*"
                        type="file"
                        onChange={changeFileHandler}
                      />
                    )}
                    <AttachFile />
                  </IconButton>
                  <Dialog
                    open={isFilePicked}
                    onClose={handleModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Send Document"}
                    </DialogTitle>
                    <DialogContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          overflowY: "initial",
                        }}
                      >
                        {isFilePicked && selectedFileType.includes("image") && (
                          <img
                            style={{ height: "50%", width: "50%" }}
                            src={URL.createObjectURL(selectedFile)}
                            alt=""
                          />
                        )}
                        {isFilePicked && selectedFileType.includes("video") && (
                          <video width="50%" height="50%" autoPlay>
                            <source src={URL.createObjectURL(selectedFile)} />
                          </video>
                        )}
                      </Box>
                      {isFilePicked &&
                        selectedFileType.includes("application") && (
                          <Box>
                            <Typography variant="body2">
                              {selectedFileName}
                            </Typography>
                            <TextSnippetSharp width={"40%"} height={"40%"} />
                          </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleModalClose}>Cancel</Button>
                      <Button onClick={handleDocumentSend} autoFocus>
                        Send
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </Grid>
              <Grid item xs={10} sx={{ padding: "6px 0px" }}>
                <Box 
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <TextField
                    fullWidth
                    placeholder="Enter Message to send.."
                    size="small"
                    multiline
                    variant="outlined"
                    maxRows={3}
                    value={inputMessage}
                    disabled={!selected_conv}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => {
                      e.preventDefault();
                      setInputMessage(e.target.value);
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: "20px",
                        opacity: 1,
                      },
                    }}
                  /> */}
                  <Box sx={{display: "none"}}>
                    <input type="text" id="PreventChromeAutocomplete" 
                      name="PreventChromeAutocomplete" autoComplete="address-level4" />
                  </Box>  
                  <input type="text"
                  className="outline_focus"
                  autoComplete="off"
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      width: "100%",
                      padding: "8px",
                      border: "1px solid",
                      minHeight: "10px",
                      borderRadius: "12px",
                      textAlign: "left",
                      maxHeight: "35px",
                      fontFamily: "Arial",
                      fontSize: "14px",
                      resize: "none",
                      backgroundColor:'transparent',
                      borderColor: chatInputPerimeterColor
                      
                    }}
                    placeholder={placeholder}
                    onChange={(e) => {
                      e.preventDefault();
                      setInputMessage(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    value={inputMessage}
                    disabled={!selected_conv}
                  >
                  </input>
                </Box>
              </Grid>
              <Grid item xs={1} sx={{}}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <IconButton
                    sx={{ opacity: 1 , color:context?"#140aeb85":"rgba(0, 0, 0, 0.54)"}}
                    onClick={handleSendMessage}
                    disabled={!selected_conv}
                  >
                    <SendRounded />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Box>
  );
};

const MessageListWindow = memo(({ height, width, FormattedMsgList }) => {

  const Row = memo(({ data: formatingMsgList, index, style }) => {
    switch (FormattedMsgList[index].type) {
      case "date":
        return (
          <ShowDate
            data={FormattedMsgList[index].data}
            style={style}
            index={index}
          />
        );
      case "typing":
        return (
          <Typing
            style={style}
            index={index}
          />
        );
      case "sent_attachment":
        return (
          <SendDocument
            data={FormattedMsgList[index].data}
            style={style}
            index={index}
            height={height}
            width={width}
          />
        );
      case "sent_msg":
        return (
          <SendMessage
            data={FormattedMsgList[index].data}
            style={style}
            index={index}
            height={height}
            width={width}
          />
        );

      case "receive_attachment":
        return (
          <RecieveDocument
            data={FormattedMsgList[index].data}
            style={style}
            index={index}
            height={height}
            width={width}
          />
        );

      case "receive_msg":
        return (
          <RecievedMessage
            data={FormattedMsgList[index].data}
            style={style}
            index={index}
            height={height}
            width={width}
          />
        );

      default:
        return <Box key={index}></Box>;
    }
  }, areEqual);
  const listRef = useRef();

  useEffect(() => {
    listRef.current.resetAfterIndex(0);
    setTimeout(() => {
      if (listRef?.current) {
        listRef.current.scrollToItem(FormattedMsgList.length);
      }
    }, 50);
  }, [FormattedMsgList]);

  const getContentCount = (a, b) =>{
    if(b>400){
      let c = 0.111421 * b + 5.9805
      let result = Math.floor(a/c) + 1
      return result
    } else {
      let c = 0.0967742 * b + 3.22581
      let result = Math.floor(a/c) + 1
      return result
    }
  }
  const getCustomBodySize = (body, width, attach=true) =>{
    let len = 50
    let bodySplit = body.split("\n")
    if(attach){
      len+=15
      if(width>400){
        len += 300
      } else {
        len += 0.753086 * width -13.963
      }
    }
    for(let arr of bodySplit){
        if(width>400){
          len += (getContentCount(arr?.length,width)) * 20
        } else {
          len += (getContentCount(arr?.length,width)) * 20.02
        }
    }
    return len
  }
  const getItemSize = (index) => {
    let len
    switch (FormattedMsgList[index]?.type) {
      case "date":
        return 40;
      case "sent_attachment":
        len = getCustomBodySize(FormattedMsgList[index]?.data?.body ?? "", width, true)
        return len;
      case "sent_msg":
        len = getCustomBodySize(FormattedMsgList[index]?.data?.body ?? "", width, false)
        return len;
      case "receive_attachment":
        len = getCustomBodySize(FormattedMsgList[index]?.data?.body ?? "", width, true)
        return len;
      case "receive_msg":
        len = getCustomBodySize(FormattedMsgList[index]?.data?.body ?? "", width, false)
        return len;
      case "padding":
        return FormattedMsgList[index]?.data;
      default:
        return 50;
    }
  };

  return (
    <VariableSizeList
      height={height}
      width={width}
      itemSize={getItemSize}
      itemCount={FormattedMsgList.length}
      ref={listRef}
    >
      {Row}
    </VariableSizeList>
  );
}, areEqual);
export default MessageList;
