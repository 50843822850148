import React, { useContext, useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { AuthContext } from "../Components/Context/AuthContext";
const PrivateRoute = () => {
  const { isAuthenticated, isAuthenticatedSession } = useContext(AuthContext);
  const [auth, setAuth] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const check = async ()=>{
    if( await isAuthenticated()){
      setAuth(true)
    }
    else if (await isAuthenticatedSession()){
      setAuth(true)
    } 
    else{
      setAuth(false)
    }
    setLoaded(true)
  }
  useEffect(()=>{
    check()
  },[]) //eslint-disable-line
  return <> {
    loaded && 
    (
      auth ? 
      (
        <Outlet />
      ) : (
        <Navigate to={"/login?redirectURL=" + window.location.pathname} />
      )
    )
  }
  </>
};
export default PrivateRoute;
