import { ListItem, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ShowDate = ({ data, style, index }) => {
  return (
    <ListItem
      disableGutters
      sx={{ justifyContent: "center" }}
      style={style}
      component="div"
      key={index}
    >
      <Paper
        elevation={3}
        sx={{
          width: "150px",
          display: "flex",
          justifyContent: "center",
          borderRadius:'14px'
        }}
      >
        <Box sx={{ position: "relative", p: 0 }}>
          <Typography sx={{ overflowWrap: "break-word" }} variant="body2">
            {data}
          </Typography>
        </Box>
      </Paper>
    </ListItem>
  );
};

export default ShowDate;
